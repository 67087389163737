import useSWR from 'swr'
import { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Roles, needOrganisationsUpfront } from '@/config'
import { useAuth, useI18n, useScope } from '@/context'
import { getSalesRegions, getCountries, getOrganisations } from '@/api'
import { useOnClickOutside } from '@/hooks'
import { Spinner } from '@/components'

import styles from './scope-selector.module.css'

const Loading = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 'var(--standard-size) 0'}}>
            <Spinner />
        </div>
    )
}

const SalesRegions = () => {
    const i18n = useI18n()
    const auth = useAuth()
    const { data: salesRegions } = useSWR('sales-regions', getSalesRegions)

    return (
        salesRegions ? 
            <ol>
                { salesRegions && (salesRegions.length > 1) && <li className={styles.pinned}><Link to={`/${i18n.locale}/${auth.user.country.commercialZone.id}-region/`}>{auth.user.country.commercialZone.name}</Link></li> }
                { salesRegions && salesRegions.map(({id, name}) => (
                    <li key={id}><Link to={`/${i18n.locale}/${id}-region/`}>{name}</Link></li>)
                )}
            </ol>
            :
            <ol><li><Loading /></li></ol>
    )
}

const CountriesForSalesRegion = ({salesRegionId}) => {
    const i18n = useI18n()
    const auth = useAuth()
    const { data: countries } = useSWR('countries',getCountries)

    return (
        countries ? 
            countries.length > 0 ?
                <ol>
                    { countries && countries.filter(country => country.commercialZone.id == salesRegionId).length > 1 && (salesRegionId === auth.user.country.commercialZone.id) && <li className={styles.pinned}><Link to={`/${i18n.locale}/${auth.user.country.iso3166.toLowerCase()}-country/`}>{auth.user.country.description}</Link></li> }
                    { countries && countries.filter(country => country.commercialZone.id == salesRegionId).map(({iso3166, description}) => (
                        <li key={iso3166}><Link to={`/${i18n.locale}/${iso3166.toLowerCase()}-country/`}>{description}</Link></li>)
                    )}
                </ol>
                :
                <ol><li><strong>{i18n.t('web.private.misc.no_countries_in_region')}</strong></li></ol>
            :
            <ol><li><Loading /></li></ol>
    )
}

CountriesForSalesRegion.propTypes = {
    salesRegionId: PropTypes.number.isRequired
}

const OrganisationsForCountry = ({countryId}) => {
    const i18n = useI18n()

    const { data } = useSWR('organisations', getOrganisations)
    const organisations = useMemo(() => {
        return data?.filter(o => !o.isArchived && o.country.iso3166 === countryId.toUpperCase())
    }, [data, countryId])

    return (
        organisations ?
            organisations.length > 0 ?
                <ol>{organisations.map(({id, name}) => (
                    <li key={id}><Link to={`/${i18n.locale}/${id.toLowerCase()}/`}>{name}</Link></li>))}
                </ol>
                :
                <ol><li><strong>{i18n.t('web.private.misc.no_organisations_in_country')}</strong></li></ol>
            :
            <ol><li><Loading /></li></ol>
    )
}

OrganisationsForCountry.propTypes = {
    countryId: PropTypes.string.isRequired
}

const OrganisationsForUser = () => {
    const i18n = useI18n()
    const { data: organisations } = useSWR('organisations', getOrganisations)

    return (
        organisations ?
            organisations.length > 0 ?
                <ol>{organisations.filter(o => !o.isArchived).map(({id, name}) => (
                    <li key={id}><Link to={`/${i18n.locale}/${id.toLowerCase()}/`}>{name}</Link></li>))}
                </ol>
                :
                <ol><li><strong>{i18n.t('web.private.misc.no_organisations_for_user')}</strong></li></ol>
            :
            <ol><li><Loading /></li></ol>
    )
}

export const ScopeSelector = () => {
    const auth = useAuth()
    const i18n = useI18n()
    const scope = useScope()
    const selectorElement = useRef()

    const { data: organisations } = useSWR(auth.user && needOrganisationsUpfront(auth.user) ? 'organisations' : null, getOrganisations)

    const closeSelector = () => {
        selectorElement.current.removeAttribute('open')
    }

    useOnClickOutside(selectorElement, closeSelector)

    const canGoUp = () => {
        const scopeDepth = {
            global: 1,
            regional: 2,
            'multi-org': 2,
            country: 3,
            'single-org': 4,
            user: 5
        }

        const roleDepth = {
            [Roles.globalAdmin]: scopeDepth.global,
            [Roles.regionalAdmin]: scopeDepth.regional,
            [Roles.multiOrgAdmin]: scopeDepth['multi-org'],
            [Roles.orgAdmin]: organisations && organisations.length > 1 ? scopeDepth['multi-org'] : scopeDepth['single-org'], // If have more than one org then parent is "my-orgs", else only org
            [Roles.globalSalesAdmin]: scopeDepth.global,
            [Roles.regionalSalesAdmin]: scopeDepth.regional,
            [Roles.countrySalesAdmin]: scopeDepth.country,
            [Roles.salesRep]: organisations && organisations.length > 1 ? scopeDepth['multi-org'] : scopeDepth['single-org'],
            [Roles.externalSalesRep]: organisations && organisations.length > 1 ? scopeDepth['multi-org'] : scopeDepth['single-org'],
            [Roles.guest]: scopeDepth.user
        }

        return roleDepth[auth.user.role] < scopeDepth[scope.type]
    }

    return scope && (
        <div className={styles.scopeSelector} data-scope-type={scope.type}>
            {canGoUp() ?
                <Link to={`../${scope.parent}/`}  aria-label={i18n.t('web.private.misc.back')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                </Link>
                :
                <Link to={`/${i18n.locale}/${scope.slug}/`} aria-label={i18n.t('web.private.misc.home')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00DEB9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <g transform="scale(0.75), translate(4,4)">
                        <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </g>
                    </svg>
                </Link>
            }
            <details ref={selectorElement}>
                <summary>
                    <span>
                        {scope.type == 'single-org' && scope.organisation && scope.organisation.masterOrganisation?.name !== scope.name &&
                            <em>{scope.organisation.masterOrganisation?.name}</em>
                        }
                        <span>{scope.name}</span>
                    </span>
                </summary>

                <div onClick={() => closeSelector()}>
                    {scope.type === 'global' && <SalesRegions />}

                    {scope.type === 'regional' && <CountriesForSalesRegion salesRegionId={scope.id} />}

                    {scope.type === 'country' && <OrganisationsForCountry countryId={scope.id} />}

                    {scope.type === 'multi-org' && <OrganisationsForUser /> }
                </div>
            </details>         
        </div>
    )
}
