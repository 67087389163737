import { useState, useEffect, createContext, useContext, forwardRef } from 'react'

import styles from './forms.module.css'

const FormContext = createContext(null)

export const Form = forwardRef(({ children, data, setData, action, validator = () => true }, ref) => {
    const [status, setStatus] = useState('waiting')
    const [errors, setErrors] = useState({})

    useEffect(() => {
        const valid = validator() 
    
        setStatus(valid ? 'ready' : 'waiting')
      }, [data, validator])

    const handleBlur = (event) => {
        if (event.target.checkValidity()) {
            setErrors({...errors, [event.target.name]: event.target.validationMessage})
        } else {
            delete errors[event.target.name]
        }
    }

    const handleChange = (event) => {
        let name = event.target.name
        let incoming
        if (event.target.type === 'checkbox' && event.target.value === 'true') {
            incoming = event.target.checked
        } else {
            incoming = event.target.value
        }
        let value = incoming

        if (name == undefined || name == null || name === '') return

        let key = null
        const dot = name.indexOf('.')
        if (dot > 0) {
            name = event.target.name.slice(0, dot)
            key = event.target.name.slice(dot+1)
        }

        if (data[name] instanceof Array) {
            if (event.target.type === 'checkbox') {
                if (event.target.checked) {
                    value = [...data[name], incoming]
                } else {
                    value = data[name].filter(v => v !== incoming)
                }
            } else {
                value = [incoming]
            }
        } else if (dot > 0 && data[name] instanceof Object) {
            if (data[name][key] instanceof Array) {
                if (event.target.type === 'checkbox') {
                    if (event.target.checked) {
                        incoming = [...data[name][key], incoming]
                    } else {
                        incoming = data[name][key].filter(v => v !== incoming)
                    }
                } else {
                    incoming = [incoming]
                }
            }

            if (event.target.type === 'checkbox') {
                if (event.target.checked) {
                    value = {
                        ...data[name],
                        [key]: incoming
                    }
                } else {
                    if (!(data[name][key] instanceof Array)) {
                        // eslint-disable-next-line no-unused-vars
                        const { [key] : omit, ...temp } = data[name]
                        value = temp
                    } else {
                        value = {
                            ...data[name],
                            [key]: incoming
                        }
                    }
                }
            } else {
                value = {
                    ...data[name],
                    [key]: incoming
                }
            }
        }

        setData({  
            ...data,
            [name]: value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setStatus('loading')
      
        if (action) {
            await action(data)
        } else {
            throw new Error('No form action specificed')
        }

        setStatus('waiting')
    }

    const setError = (field, error) => {
        field.setCustomValidity(error)
        if (error !== '') {
            setErrors({...errors, [field.name]: error})
        } else {
            setErrors(Object.keys(errors).reduce((acc, cur) => {
                return cur === field.name ? acc : {...acc, [cur]: errors[cur]}
            }, {}))
        }
    }

    const value = {
        data: data,
        errors: errors,
        setError: setError,
        status: status,
        handleChange: handleChange
    }

    return (
        <form className={styles.form} onSubmit={handleSubmit} onChange={handleChange} onBlur={handleBlur} ref={ref}>
            <FormContext.Provider value={value}>{children}</FormContext.Provider>
        </form>
    )
})

export const useForm = () => {
    const context = useContext(FormContext)
    if (!context) throw Error('useForm should be used within a child of <Form />')
    return context
}