import PropTypes from 'prop-types'
import { ReactComponent as Icons } from './icons.svg'

export const IconProvider = ({ children }) => {

    return <>
        {children}
        <div style={{display: 'none'}}><Icons /></div>
    </>
}

IconProvider.propTypes = {
    children: PropTypes.node.isRequired
}