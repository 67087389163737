// TODO: Need to be able to distingush between resource type and actual resource object
export const isPermitted = (user = null, action = null, resource = null) => {
    // Guard clauses
    if (user == null || action == null || resource == null) throw 'Must provide user, action, and resource to check permissions'
    // if (!user['role']) throw 'Must provide a user that has a role to check permissions'

    const role = user['role'] || Roles.appUser

    const rolePermissions = Permissions[role]
    if (rolePermissions === undefined) throw 'No permissions configured for the user role' // TODO: Just return false instead?

    const resourcePermissions = rolePermissions && rolePermissions[resource]
    const resourceActionPermission = resourcePermissions && resourcePermissions[action]

    const defaultPermissions = rolePermissions && rolePermissions[Resources.default]
    const defaultActionPermission = defaultPermissions && ( defaultPermissions[action] || false )

    return resourceActionPermission !== undefined ? resourceActionPermission : defaultActionPermission
}

export const canUseGlobalScope = (user) => {
    return user && [Roles.globalAdmin, Roles.globalSalesAdmin].includes(user.role)
}

export const canUseRegionalScope = (region, user) => {
    return canUseGlobalScope(user)
        ||  (
            [Roles.regionalAdmin, Roles.regionalSalesAdmin].includes(user.role)
            && user.country?.commercialZone?.id === region.id
        )
}

export const canUseCountryScope = (country, user) => {
    return canUseRegionalScope(country.commercialZone, user)
        || (
            [Roles.countrySalesAdmin].includes(user.role)
            && user.country?.iso3166 === country.iso3166
        )
}

export const canUseMyOrgsScope = (user) => {
    return user
        && [Roles.orgAdmin, Roles.salesRep, Roles.externalSalesRep, Roles.multiOrgAdmin].includes(user.role)
        // TODO:API user.organisations is not trust worthy for sales reps at present
        && (user.organisations.length > 1 || user.role === Roles.salesRep || user.role === Roles.externalSalesRep)
}

export const canUseOrganisationScope = (organisation, user) => {
    return user && (
        (user.role === Roles.globalAdmin || user.role === Roles.globalSalesAdmin)
        || ([Roles.regionalAdmin, Roles.regionalSalesAdmin].includes(user.role) && user.country?.commercialZone?.id === organisation.country.commercialZone.id)
        || ([Roles.countrySalesAdmin].includes(user.role) && user.country?.iso3166 === organisation.country.iso3166)
        || ([Roles.salesRep, Roles.externalSalesRep].includes(user.role)) // TODO: Can't use user.organisations as sales rep as API doesn't return any data at present for this
        || user.organisations.some(o => o.id === organisation.id)
    )
}

export const needOrganisationsUpfront = (user) => {
    return user && canUseMyOrgsScope(user) || (user.role && isClientRole(user.role) && (user.role !== Roles.appUser))
}

export const resourceAppropriateForScopeType = (resource, scopeType) => {
    return ResourcesPerScopeType[scopeType]?.includes(resource)
}

export const resourceInappropriateForScopeType = (resource, scopeType) => {
    return !resourceAppropriateForScopeType(resource, scopeType)
}

export const scopeAppropriateForUser = (scope, user) => {
    if (!scope || scope.id === 'none') return true

    switch (scope.type) {
        case 'global':
            return canUseGlobalScope(user)
        case 'regional':
            return canUseRegionalScope(scope.region, user)
        case 'country':
            return canUseCountryScope(scope.country, user)
        case 'multi-org':
            return canUseMyOrgsScope(user)
        case 'single-org':
            return canUseOrganisationScope(scope.organisation, user)
        case 'user':
            return true

        default:
            return false
    }
}

export const scopeInappropriateForUser = (scope, user) => {
    return !scopeAppropriateForUser(scope, user)
}

export const resourceAppropriateForUser = (resource, user) => {
    return isPermitted(user, Actions.view, resource.toUpperCase().replaceAll('-', '_'))
}

export const resourceInappropriateForUser = (resource, user) => {
    return !resourceAppropriateForUser(resource, user)
}

export const getRoleType = (role) => {
    return RoleTypes[role] || 'CLIENT'
}

export const isTristelRole = (role) => {
    return ['ADMIN', 'SALES'].includes(getRoleType(role))
}

export const isPartnerRole = (role) => {
    return getRoleType(role) === 'PARTNER'
}

export const isClientRole = (role) => {
    return getRoleType(role) === 'CLIENT'
}

export const isClient = (user) => {
    return isClientRole(user.role)
}

const ResourcesPerScopeType = {
    global: [
        'dashboard',
        'clinical-departments',
        'sales-regions',
        'countries',
        'email-templates',
        'instructions',
        'languages',
        'manufacturers',
        'medical-device-types',
        'products',
        'product-systems',
        'product-tests',
        'profile',
        'reporting',
        'resources',
        'stages',
        'users'
    ],
    regional: [
        'dashboard',
        'parent-organisations',
        'organisations',
        'profile',
        'reporting',
        'users'
    ],
    country: [
        'dashboard',
        'parent-organisations',
        'organisations',
        'profile',
        'reporting',
        'users'
    ],
    'multi-org': [
        'dashboard',
        'parent-organisations',
        'organisations',
        'profile',
        'reporting',
        'users'
    ],
    'single-org': [
        'dashboard',
        'clinical-settings',
        'disinfection-log',
        'handsets',
        'medical-devices',
        'product-systems',
        'profile',
        'reporting',
        'users',
        'training-records'
    ],
    'user': [
        'product-training',
        'training-record',
        'profile'
    ]
}

// Maps constant local names to those used in the API so can change in one place if need to
// TODO: Do we really want this hardcoded? These are likely to change over time with business need
export const Roles = {
    globalAdmin: 'GLOBAL_ADMIN',
    regionalAdmin: 'REGIONAL_ADMIN',
    multiOrgAdmin: 'MULTI_ORG_ADMIN',
    orgAdmin: 'ORG_ADMIN',
    salesRep: 'SALES_REP',
    externalSalesRep: 'EXTERNAL_SALES_REP',
    globalSalesAdmin: 'GLOBAL_SALES_ADMIN',
    regionalSalesAdmin: 'REGIONAL_SALES_ADMIN',
    countrySalesAdmin: 'COUNTRY_SALES_ADMIN',
    appUser: 'StandardUser',
    guest: 'GUEST'
}

// v2 endpoints use different role names (because reasons)
export const Roles2 = {
    globalAdmin: 'GlobalAdmin',
    regionalAdmin: 'RegionalAdmin',
    multiOrgAdmin: 'MultiOrgAdmin',
    orgAdmin: 'OrganisationAdmin',
    salesRep: 'SalesRep',
    externalSalesRep: 'ExternalSalesRep',
    globalSalesAdmin: 'GlobalSalesAdmin',
    regionalSalesAdmin: 'RegionalSalesAdmin',
    countrySalesAdmin: 'CountrySalesAdmin',
    appUser: 'StandardUser',
    guest: 'GUEST'
}

// For a given role this gives the type of user between admin, sales, and client
export const RoleTypes = {
    [Roles.globalAdmin]: 'ADMIN',
    [Roles.regionalAdmin]: 'ADMIN',
    [Roles.multiOrgAdmin]: 'CLIENT',
    [Roles.orgAdmin]: 'CLIENT',
    [Roles.salesRep]: 'SALES',
    [Roles.externalSalesRep]: 'PARTNER',
    [Roles.globalSalesAdmin]: 'SALES',
    [Roles.regionalSalesAdmin]: 'SALES',
    [Roles.countrySalesAdmin]: 'SALES',
    [Roles.appUser]: 'CLIENT',
    [Roles.guest]: 'GUEST',
    // Sigh...
    [Roles2.globalAdmin]: 'ADMIN',
    [Roles2.regionalAdmin]: 'ADMIN',
    [Roles2.multiOrgAdmin]: 'CLIENT',
    [Roles2.orgAdmin]: 'CLIENT',
    [Roles2.salesRep]: 'SALES',
    [Roles2.externalSalesRep]: 'PARTNER',
    [Roles2.globalSalesAdmin]: 'SALES',
    [Roles2.regionalSalesAdmin]: 'SALES',
    [Roles2.countrySalesAdmin]: 'SALES',
    [Roles2.appUser]: 'CLIENT',
    [Roles2.guest]: 'GUEST'
}

export const Actions = {
    view: 'VIEW',
    create: 'CREATE',
    update: 'UPDATE',
    archive: 'ARCHIVE',
    delete: 'DELETE'
}

export const Resources = {
    default: 'DEFAULT', // SPECIAL ONE
    clinicalSettings: 'CLINICAL_SETTINGS',
    clinicalDepartments: 'CLINICAL_DEPARTMENTS',
    countries: 'COUNTRIES',
    dashboard: 'DASHBOARD',
    disinfectionLog: 'DISINFECTION_LOG',
    draftUsers: 'DRAFT_USERS',
    emailTemplates: 'EMAIL_TEMPLATES',
    handsets: 'HANDSETS',
    instructions: 'INSTRUCTIONS',
    feedback: 'FEEDBACK',
    genericResources: 'GENERIC_RESOURCES',
    knowledgeHub: 'KNOWLEDGE_HUB',
    languages: 'LANGUAGES',
    manufacturers: 'MANUFACTURERS',
    medicalDevices: 'MEDICAL_DEVICES',
    medicalDeviceTypes: 'MEDICAL_DEVICE_TYPES',
    organisations: 'ORGANISATIONS',
    organisationPreferences: 'ORGANISATION_PREFERENCES',
    parentOrganisations: 'PARENT_ORGANISATIONS',
    products: 'PRODUCTS',
    productResources: 'PRODUCT_RESOURCES',
    productSystems: 'PRODUCT_SYSTEMS',
    productTests: 'PRODUCT_TESTS',
    productTraining: 'PRODUCT_TRAINING',
    profile: 'PROFILE',
    regions: 'REGIONS',
    reporting: 'REPORTING',
    salesRegions: 'SALES_REGIONS',
    salesReps: 'SALES_REPS',
    stages: 'STAGES',
    support: 'SUPPORT',
    trainingRecord: 'TRAINING_RECORD', // for an individual
    trainingRecords: 'TRAINING_RECORDS', // for an organisation
    users: 'USERS'
}

export const Permissions = {
    [Roles.globalAdmin]: {
        [Resources.default]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: true
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.handsets]: {
            [Actions.update]: false
        },
        [Resources.draftUsers]: {
            [Actions.create]: false
        },
        [Resources.feedback]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        }
    },
    [Roles.regionalAdmin]: {
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.users]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.reporting]: {
            [Actions.view]: true
        },
        [Resources.parentOrganisations]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisations]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisationPreferences]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.handsets]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.draftUsers]: {
            [Actions.create]: false,
            [Actions.update]: false
        },
        [Resources.medicalDevices]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.clinicalSettings]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.productSystems]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.trainingRecords]: {
            [Actions.view]: true
        }
    },
    [Roles.multiOrgAdmin]: {
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.clinicalSettings]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.reporting]: {
            [Actions.view]: true
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.parentOrganisations]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisations]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisationPreferences]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.users]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.draftUsers]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.medicalDevices]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.clinicalSettings]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.productSystems]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.trainingRecords]: {
            [Actions.view]: true
        }
    },
    [Roles.orgAdmin]: {
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.reporting]: {
            [Actions.view]: true
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisations]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.organisationPreferences]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.medicalDevices]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.clinicalSettings]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.productSystems]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.handsets]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.users]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.draftUsers]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.productTraining]: {
            [Actions.view]: true
        },
        [Resources.trainingRecord]: {
            [Actions.view]: true
        },
        [Resources.trainingRecords]: {
            [Actions.view]: true
        }
    },
    [Roles.salesRep]: {
        [Resources.default]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.parentOrganisations]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisations]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisationPreferences]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.handsets]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: false
        },
        [Resources.draftUsers]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.productSystems]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.productTraining]: {
            [Actions.view]: false
        },
        [Resources.trainingRecord]: {
            [Actions.view]: false
        },
        [Resources.trainingRecords]: {
            [Actions.view]: true
        }   
    },
    [Roles.externalSalesRep]: {
        [Resources.default]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.organisations]: {
            [Actions.view]: true,
            [Actions.update]: true
        },
        [Resources.users]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true
        },
        [Resources.draftUsers]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.update]: true
        }
    },
    [Roles.globalSalesAdmin]: {
        [Resources.default]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.organisations]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.parentOrganisations]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.organisationPreferences]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.handsets]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.draftUsers]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false
        },
        [Resources.reporting]: {
            [Actions.view]: true
        },
        [Resources.salesRegions]: {
            [Actions.view]: true
        },
        [Resources.countries]: {
            [Actions.view]: true
        },
        [Resources.users]: {
            [Actions.view]: true
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.disinfectionLog]: {
            [Actions.create]: false
        },
        [Resources.feedback]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.productSystems]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.trainingRecords]: {
            [Actions.view]: true
        }
    },
    [Roles.regionalSalesAdmin]: {
        [Resources.default]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.handsets]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.draftUsers]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false
        },
        [Resources.reporting]: {
            [Actions.view]: true
        },
        [Resources.organisationPreferences]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.productSystems]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.trainingRecords]: {
            [Actions.view]: true
        }
    },
    [Roles.countrySalesAdmin]: {
        [Resources.default]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: true,
            [Actions.archive]: true,
            [Actions.delete]: false
        },
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.disinfectionLog]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.organisationPreferences]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.reporting]: {
            [Actions.view]: true
        },
        [Resources.profile]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: true,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.handsets]: {
            [Actions.view]: true,
            [Actions.create]: true,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.draftUsers]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false
        },
        [Resources.productSystems]: {
            [Actions.view]: true,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.trainingRecords]: {
            [Actions.view]: true
        }
    },
    [Roles.appUser]: {
        [Resources.default]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.productTraining]: {
            [Actions.view]: true
        },
        [Resources.trainingRecord]: {
            [Actions.view]: true
        },
        [Resources.profile]: {
            [Actions.view]: true
        }
    },
    [Roles.guest]: {
        [Resources.default]: {
            [Actions.view]: false,
            [Actions.create]: false,
            [Actions.update]: false,
            [Actions.archive]: false,
            [Actions.delete]: false
        },
        [Resources.dashboard]: {
            [Actions.view]: true
        },
        [Resources.productTraining]: {
            [Actions.view]: true
        },
        [Resources.profile]: {
            [Actions.view]: true
        }
    }
}