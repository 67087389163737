import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Roles } from '@/config'
import { storage } from '@/utils'
import { callLogin, callRequestPasswordReset, callResetPassword, callCheckPasswordCode } from '@/api'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {

  useEffect(() => {
    const storageListener = window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        if (event.key === 'user') {
          if ( event.newValue !== null && (event.oldValue === null || event.oldValue !== event.newValue) ) {
            window.location.pathname = `/en-gb/login-changed/`
          } else if (event.newValue === null) {
            window.location.pathname = `/en-gb/logged-out/`
          }
        }
      }
    }, false)
  
    return () => {
      window.removeEventListener('storage', storageListener)
    }
  }, [])
  
  const deauthorise = () => {
    setToken(null), storage.remove('token')
    setUser(null), storage.remove('user')
    setRegion(null), storage.remove('region')
  }
  
  const getAndValidateToken = () => {
    const token = storage.get('token')
    if (!token || !token.access_token) {
      return null
    }
    return token
  }
  
  const getAndValidateUser = () => {
    const user = storage.get('user')
    if (!user) {
      return null
    }
    return user
  }

  const getRegion = () => {
    return storage.get('region')
  }

  const [token, setToken] = useState(() => getAndValidateToken())
  const [user, setUser] = useState(() => getAndValidateUser())
  const [region, setRegion] = useState(() => getRegion())

  const value = useMemo(
    () => ({
      token,
      user,
      region,
      login: (loginSubmission) => {
        return callLogin(loginSubmission).then( (result) => {
          if (result && result.successfully && result.data && result.data.token && result.data.details) {
            const user = {
              ...result.data.details,
              role: result.data.details.role || Roles.appUser
            }
            setToken(result.data.token), storage.set('token', {...result.data.token, expires_on: new Date(new Date().getTime() + (result.data.token.expires_in * 1000))})
            setUser(user), storage.set('user', user)
            setRegion(result.data.region), storage.set('region', user.region)
            return result.data.details
          } else {
            return false
          }
        })
      },
      persist: (userData, orgData) => {
        // Note that country object from org endpoint differs to that from weblogin so being careful below with it
        const user = {
          id: userData.id,
          role: 'GUEST',
          pin: userData.pin,
          userInfo: {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.emailAddress
          },
          jobTitle: userData.jobTitle,
          clinicalDepartmentId: userData.clinicalDepartmentId,
          marketingOptedIn: userData.marketingOptedIn,
          country: {
            iso3166: orgData.country.code,
            description: orgData.country.description,
            regionCode: orgData.country.region,
            isArchived: orgData.country.isArchived
          },
          organisations: [orgData]
        }
        setUser(user), storage.set('user', user)
        setToken(null), storage.remove('token')
        return user
      },
      logout: (goto) => {
        deauthorise()
        // scope or guard provider will catch auth change and handle shoving user over to login screen if not told to go somewhere specific here
        if (goto) {
          window.location = goto
        }
      },
      requestPasswordReset: (email) => {
        return callRequestPasswordReset(email).then( (result) => {
          return result
        })
      },
      verifyPasswordCode: (code) => {
        return callCheckPasswordCode(code).then( (result) => {
          return result?.data
        })
      },
      resetPassword: (data) => {
        return callResetPassword(data).then( (result) => {
          if (result && result.successfully) {
            deauthorise() // In case was logged in as another user at the time (e.g. admin doing testing)
            return true
          } else {
            return false
          }
        })
      },
      refreshUser: (delta) => {
        storage.set('user', { ...user, ...delta })
        setUser({ ...user, ...delta })
      },
      refreshUserInfo: (delta) => {
        const userInfo = { ...user.userInfo, ...delta }
        storage.set('user', {...user, userInfo: userInfo})
        setUser({...user, userInfo: userInfo})
      }
    }),
    [token, user, region]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
} 

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw Error('useAuth should be used within <AuthProvider />')
  return context
}