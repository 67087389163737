import { useEffect, useRef, useState } from 'react'
import { NavLink, Link, useLocation } from "react-router-dom"
import PropTypes from 'prop-types'

import { isClient, isPermitted, resourceAppropriateForScopeType } from "@/config"
import { useI18n, useScope, useAuth } from "@/context"
import { ScopeSelector, InfoBar, Icon } from "@/components"

import styles from './nav.module.css'

const RESPONSIVE_MENU_LIMIT = 960
const ROOT_RESOURCE = 'dashboard'

const ResourceLink = ({ title, icon, resource, scope }) => {
    const i18n = useI18n()
    const auth = useAuth()
    const location = useLocation()

    const localisedScopedPath = (resource) => {
        const target = (resource === ROOT_RESOURCE) ? '' : `${resource}/`
        return `/${i18n.locale}/${scope.slug}/${target}`
    }

    // For some reason NavLink is not highlighting active descendant pages.
    // GitHub bug reports indicate it might be due to using trailing slashes
    // but maintainers have marked them as "intended behaviour" so as a
    // workaround we determine active class name ourselves here
    const determineActiveClass = (target) => {
        return () => (location.pathname.includes(`/${target}/`) || location.pathname == localisedScopedPath(target)) ? 'active' : null
    }

    return (
        resourceAppropriateForScopeType(resource, scope.type)
        && isPermitted(auth.user, 'VIEW', resource.toUpperCase().replace('-', '_'))
        && <li>
            <NavLink to={localisedScopedPath(resource)} className={determineActiveClass(resource)}>
                <Icon name={icon} /> <span>{title}</span>
            </NavLink>
        </li>
    )
}

ResourceLink.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    scope: PropTypes.object.isRequired
}

const ResourceGroup = ({ title, icon, resources, scope }) => {
    const auth = useAuth()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [activeClass, setActiveClass] = useState()

    const handleToggle = (event) => {
        event.preventDefault()
        setOpen(!open)
    }

    useEffect(() => {
        const isActive = () => {
            return resources.some(r => location.pathname.includes(`/${r.name}/`))
        }
        
        const determineActiveClass = () => {
            return isActive() ? 'active' : null
        }

        setOpen(isActive)
        setActiveClass(determineActiveClass())
    }, [location, resources])
    

    return (
        resources.some(resource => {
            return resourceAppropriateForScopeType(resource.name, scope.type)
                && isPermitted(auth.user, 'VIEW', resource.name.toUpperCase().replace('-', '_'))
        }) &&
        <li>
            <details className={activeClass} open={open}>
                <summary onClick={handleToggle}><Icon name={icon} /> {title}</summary>
                <ul>
                { resources.map(resource => { 
                    return <ResourceLink key={resource} resource={resource.name} icon={resource.icon || 'bullet'} title={resource.title} scope={scope} />
                }) }
                </ul>
            </details>
        </li>
    )
}

ResourceGroup.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    resources: PropTypes.array.isRequired,
    scope: PropTypes.object.isRequired
}

export const Nav = () => {
    const i18n = useI18n()
    const scope = useScope()
    const auth = useAuth()

    const [expanded, setExpanded] = useState(window && window.innerWidth > 800)
    
    const navBar = useRef(null)
    const [sticky, setSticky] = useState(false)

    useEffect(() => {
        if (navBar?.current && navBar.current.clientHeight <= window.innerHeight) {
            setSticky(true)
        }
    }, [navBar])

    const geoManagementResources = [
        { name: 'sales-regions', title: i18n.t('web.private.page.sales_regions.title'), icon: 'sales-regions' },
        { name: 'countries', title: i18n.t('web.private.page.countries.title'), icon: 'countries' },
        { name: 'languages', title: i18n.t('web.private.page.languages.title'), icon: 'languages' }
    ]

    const productManagementResources = [
        { name: 'product-systems', title: i18n.t('web.private.page.product_systems.title'), icon: 'product-systems' },
        { name: 'stages', title: i18n.t('web.private.page.stages.title'), icon: 'stages' },
        { name: 'products', title: i18n.t('web.private.page.products.title'), icon: 'products' },
        { name: 'instructions', title: i18n.t('web.private.page.instructions.title'), icon: 'instructions' }
    ]

    const deviceManagementResources = [
        { name: 'manufacturers', title: i18n.t('web.private.page.manufacturers.title'), icon: 'manufacturers' },
        { name: 'medical-device-types', title: i18n.t('web.private.page.medical_device_types.title'), icon: 'device-types' }
    ]

    const handleMenuClick = (event) => {
        if (window.innerWidth <= RESPONSIVE_MENU_LIMIT && event.target.tagName === 'A') {
            setExpanded(false)
        }
    }

    return (
        <nav className={styles.nav} data-expanded={'' + expanded} ref={navBar} data-sticky={sticky}>
            <p className={styles.logo}><Link to={`/${i18n.locale}/`}>3T</Link></p>

            <div className={styles.openMenu}>
                <button onClick={() => setExpanded(true)} aria-label={i18n.t('web.private.misc.toggle_menu')}><Icon name="menu" /></button>
            </div>

            {scope ? <div className={styles.menu}>

                <h2>Menu</h2>

                <ScopeSelector />

                <div className={styles.closeMenu}>
                    <button onClick={() => setExpanded(false)} aria-label={i18n.t('web.private.misc.toggle_menu')}><Icon name="close" /></button>
                </div>

                <ul onClick={handleMenuClick}>
                    <ResourceLink resource="dashboard" icon="dashboard" title={i18n.t('web.private.page.dashboard.title')} scope={scope} />

                    { scope.type == 'single-org' && scope.organisation.organisationType === 'TrainingAndTraceability' &&
                        <>
                        <ResourceLink resource="disinfection-log" icon="log" title={i18n.t('web.private.page.disinfection_log.title')} scope={scope} />
                        </>
                    }

                    { scope.isTristel &&
                        <>
                        <ResourceGroup resources={geoManagementResources} icon="geo-config" title={i18n.t('web.private.page_group.geo_config.title')} scope={scope} />
                        <ResourceGroup resources={productManagementResources} icon="product-config" title={i18n.t('web.private.page_group.product_config.title')} scope={scope} />
                        <ResourceGroup resources={deviceManagementResources} icon="settings" title={i18n.t('web.private.page_group.device_config.title')} scope={scope} />
                        <ResourceLink resource="product-tests" icon="training-product" title={i18n.t('web.private.page.product_tests.title')} scope={scope} />
                        <ResourceLink resource="clinical-departments" icon="departments" title={i18n.t('web.private.page.clinical_departments.title')} scope={scope} />
                        <ResourceLink resource="parent-organisations" icon="organisations" title={i18n.t('web.private.page.parent_organisations.title')} scope={scope} />
                        <ResourceLink resource="organisations" icon="organisations" title={i18n.t('web.private.page.organisations.title')} scope={scope} />
                        </>
                    }

                    { scope.type == 'multi-org' &&
                        <>
                        <ResourceLink resource="organisations" icon="organisations" title={i18n.t('web.private.page.organisations.title')} scope={scope} />
                        </>
                    }

                    { scope.type == 'single-org' && scope.organisation.organisationType === 'TrainingAndTraceability' &&
                        <>
                        <ResourceLink resource="clinical-settings" icon="clinical-settings" title={i18n.t('web.private.page.clinical_settings.title')} scope={scope} />
                        <ResourceLink resource="medical-devices" icon="devices" title={i18n.t('web.private.page.medical_devices.title')} scope={scope} />
                        <ResourceLink resource="handsets" icon="handsets" title={i18n.t('web.private.page.handsets.title')} scope={scope} />
                        <ResourceLink resource="product-systems" icon="product-systems" title={i18n.t('web.private.page.product_systems.title')} scope={scope} />
                        </>
                    }

                    { scope.type == 'single-org' &&
                        <>
                        <ResourceLink resource="training-records" icon="training-record" title={i18n.t('web.private.page.training_records.title')} scope={scope} />
                        </>
                    }

                    <ResourceLink resource="users" icon="users" title={i18n.t('web.private.page.users.title')} scope={scope} />

                    { isClient(auth.user) && <>
                        <ResourceLink resource="product-training" icon="training-product" title={i18n.t('web.private.page.product_training.title')} scope={scope} />
                        <ResourceLink resource="training-record" icon="training-record" title={i18n.t('web.private.page.training_record.title')} scope={scope} />
                    </> }
                </ul>

            </div> : '' }

            <div className={styles.helpAndToggle}>
                { scope && scope.type === 'single-org' && <InfoBar organisation={scope.organisation} /> }
                <div className={styles.toggle}>
                    <button onClick={() => setExpanded(!expanded)} aria-label={i18n.t('web.private.misc.toggle_menu')}><Icon name={expanded ? 'shrink' : 'expand'} /></button>
                </div>
            </div>
        </nav>
    )
}