import { Link } from 'react-router-dom'

import { useI18n } from '@/context'
import { Icon } from '@/components'

export const BackLink = () => {
    const i18n = useI18n()

    const goBackIfCan = (e) => {
        if (window.history.length > 0) {
            e.preventDefault()
            window.history.go(-1)
        }
    }

    return (
        <Link to="../" onClick={goBackIfCan}><Icon name="left" /> {i18n.t('web.private.misc.back')}</Link>
    )
} 
