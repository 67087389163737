import { callAPI } from '.'
import { Roles2, isClientRole } from '@/config'
import { TRISTEL_MASTER_ORG_ID } from '@/utils'

const PARENT_ORG_TYPES_FOR_COUNTRY_SCOPE = ['Internal', 'Distributor']

export const getUsers = async (scope) => {
    // We can't pass multiple filters to the API so we have to do this in two passes...
    const rawUsers = await callAPI('v2/users', 'GET', scope.userFilter)
    if (scope.type === 'country') {
        return rawUsers.filter(user => PARENT_ORG_TYPES_FOR_COUNTRY_SCOPE.includes(user.parentOrganisation.type))
    } else if (scope.type === 'multi-org') {
        return rawUsers.filter(user => user.parentOrganisation.id !== TRISTEL_MASTER_ORG_ID)
    } else if (scope.type === 'single-org') {
        return rawUsers.filter(user => user.parentOrganisation.id === scope.masterOrgId)
    } else {
        return rawUsers
    }
}

export const getUnverifiedUsers = async (organisationId) => {
    return callAPI(`v2/organisations/${organisationId}/draft-users`)
}

export const rejectUnverifiedUser = async (organisationId, user) => {
    return callAPI(`v2/organisations/${organisationId}/draft-users/${user.id}`, 'DELETE')
}

export const approveUnverifiedUser = async (organisationId, user, params) => {
    return callAPI(`v2/organisations/${organisationId}/draft-users/${user.id}/approve`, 'POST', params)
}

export const getSalesReps = async (params, filter) => {
    // TODO:API can't filter API on more than one role at a time so have to do one call per role here...
    const sr = callAPI('v2/users', 'GET', { ...params, role: Roles2.salesRep }, filter)
    const esr = callAPI('v2/users', 'GET', { ...params, role: Roles2.externalSalesRep }, filter)
    
    const results = await Promise.allSettled([sr, esr])

    return (results[0].value.concat(results[1].value)).sort((a, b) => a.firstName.localeCompare(b.firstName) )
}

export const registerUser = async (organisationId, params) => {
    return callAPI(`v2/organisations/${organisationId}/draft-users`, 'PUT', params)
}

export const addUser = async (params) => {
    const commonParams = {
        masterOrganisationId: params['masterOrganisationId'],
        staffId: params['staffId'],
        firstName: params['firstName'],
        lastName: params['lastName'],
        cultureId: params['cultureId'],
        email: params['email']
    }

    let conditionalParams = {}
    if (params['region']) conditionalParams['region'] = params['region']
    if (params['country']) conditionalParams['country'] = params['country']
    if (params['role']) conditionalParams['role'] = params['role']
    if (params['jobTitle']) conditionalParams['jobTitle'] = params['jobTitle']

    if (isClientRole(params['role'])) {
        if (params['pin']) conditionalParams['pin'] = params['pin']
    }

    if (params['organisations'] && params['organisations'] != []) conditionalParams['organisations'] = params['organisations']

    return callAPI('v1/AddUser', 'POST', {
        ...commonParams,
        ...conditionalParams
    })
}

export const updateUser = async (params) => {
    if (params['products'] && params['products'] instanceof Array) {
        params.products = params.products.map(id => parseInt(id))
    }
    return callAPI('v1/UpdateUser', 'POST', params)
}

export const addOrganisationToUser = async (params) => {
    return callAPI('v1/AddOrganisationToUser', 'POST', params)
}

export const removeOrganisationFromUser = async (params) => {
    return callAPI('v1/RemoveOrganisationFromUser', 'POST', params)
}

export const getClinicalDepartmentsForUserPerOrganisation = async (user) => {
    const fetchers = user.organisations.sort().map((orgId) => {
        return callAPI(`v2/organisations/${orgId}/users/${user.id}/clinical-departments`)
    })
    const results = await Promise.allSettled(fetchers)

    return user.organisations.sort().reduce((agg, orgId, i) => {
        if (results[i] && results[i].value && results[i].value instanceof Array) {
            agg[orgId] = results[i].value.map(dept => dept.id)
        }
        return agg
    }, {})
}

export const setClinicalDepartmentsForUser = async (organisationId, userId, depts) => {
    return callAPI(`v2/organisations/${organisationId}/users/${userId}/clinical-departments`, 'PUT', depts)
}

export const removeClinicalDepartmentFromUserOrganisation = async (organisationId, userId, deptId) => {
    return callAPI(`v2/organisations/${organisationId}/users/${userId}/clinical-departments/${deptId}`, 'DELETE')
}

export const checkIfEmailAndStaffIdExists = async (params, abortKey) => {
    return callAPI('v1/CheckIfEmailAndStaffIdExists', 'POST', params, null, abortKey)
}

export const archiveUser = async (userId) => {
    if (!userId) {
        throw new Error('Expected user ID parameter when archiving a user')
    }

    return callAPI('v1/UpdateUser', 'POST', {
        id: userId,
        isArchived: true
    })
}

export const restoreUser = async (userId) => {
    if (!userId) {
        throw new Error('Expected user ID parameter when restoring a user')
    }

    return callAPI('v1/UpdateUser', 'POST', {
        id: userId,
        isArchived: false
    })
}
